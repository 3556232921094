import { useContext } from "react"
import BeigeSection from "../elements/BeigeSection"
import ReceiptUpload from "../receipt/ReceiptUpload"
import { Context } from "../../store/store"

const Sogehts = () => {
	const { dispatch } = useContext(Context)

	return (
		<>
			<div className="relative grid grid-cols-1 bg-yellow px-4">
				<div className="">
					<p className="mt-6 text-center font-Roboto text-[28px] font-bold uppercase  text-red lg:mb-0 lg:mt-12 lg:pt-12  lg:text-[90px] xl:pt-0">So einfach geht's:</p>
					<div className="sogehts  py-6 text-[40px] font-bold lg:py-10 lg:pb-24">
						<p className="mb:12 mr-0 bg-red font-Roboto font-bold text-yellow">1</p>
						<span className="mb-6 mt-4 text-center font-Roboto text-[24px] uppercase text-red lg:mt-12 lg:text-[50px]">Kaufe ein Hawaiian Tropic Produkt</span>
					</div>
					<div className="mx-auto mb-24 grid max-w-[1600px] grid-cols-1 items-center justify-items-center md:grid-cols-3">
						<div className="mr-12 hidden justify-items-end self-end justify-self-end md:block">
							<a href=" https://www.dm.de/store" target="_blank">
								<img src={process.env.PUBLIC_URL + "/images/knossi/dm-filiale.png"} className="max-w-[250px]"></img>
							</a>
						</div>
						<div>
							<img src={process.env.PUBLIC_URL + "/images/hawaiian-products.png"} className="max-w-[275px] md:max-w-full"></img>
						</div>
						<div className="ml-12 hidden justify-items-end self-end justify-self-start md:block">
							<a href="https://www.dm.de/marken/hawaiian-tropic-66910" target="_blank">
								<img src={process.env.PUBLIC_URL + "/images/knossi/dm-online.png"} className="max-w-[250px]"></img>
							</a>
						</div>
						<div className="mt-6 grid grid-cols-2 gap-6 md:hidden">
							<div className=" justify-items-end self-end">
								<a href=" https://www.dm.de/store" target="_blank">
									<img src={process.env.PUBLIC_URL + "/images/knossi/dm-filiale.png"} className="max-w-[120px]"></img>
								</a>
							</div>
							<div className=" justify-items-start self-start justify-self-start ">
								<a href="https://www.dm.de/marken/hawaiian-tropic-66910 " target="_blank">
									<img src={process.env.PUBLIC_URL + "/images/knossi/dm-online.png"} className="max-w-[120px]"></img>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="js-sogehts relative z-0 mx-auto -mt-[1px] grid  grid-cols-1 flex-col items-center bg-yellow">
				<div className="upload sogehts items-center bg-green  py-10 px-4 lg:py-24">
					<p className="bg-yellow font-Roboto text-green">2</p>

					<span className="mb-6 text-center font-Roboto text-[24px] uppercase text-yellow lg:mt-12 lg:text-[50px]">Lade hier deinen Rechnungsbeleg hoch</span>
					<div className="grid grid-cols-1 md:mt-24 md:grid-cols-[40%_60%] md:items-center md:justify-items-center ">
						<div className="mt-2 mb-2  hidden md:grid">
							<img
								src={process.env.PUBLIC_URL + "/images/knossi/bon-upload.png"}
								className="arrow-white mb-4 max-w-[100px] self-end justify-self-end lg:mb-0 lg:max-w-[220px]"
								alt=""
							/>
						</div>
						<div>
							<ReceiptUpload dispatch={dispatch} />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Sogehts
