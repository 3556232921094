import React from "react"
import RedSection from "../elements/RedSection"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ERRORTYPES, MAX_PRODUCT_PRICE } from "../../config"
import { Context } from "../../store/store"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"
import Legal from "./Legal"
import { placeOrder } from "./OrderFct"
import Person from "./Person"
import PreFillForm from "./PreFillForm"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import Product from "./Product"

const Form = () => {
	const { globalState, dispatch } = useContext(Context)
	const [validation, setValidation] = useState({})
	const navigate = useNavigate()
	const [formError, setFormError] = useState<ERRORTYPES | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	function formElementChanged(e, key) {
		// special handling for checkboxes
		if (e.target.type === "checkbox") {
			e.target.value = e.target.checked
		}

		dispatch({
			type: "SET_ORDER_ENTRY",
			key: key,
			value: e.target.value
				.replace(/(<([^>]+)>)/gi, "")
				.replace("https://www", "")
				.replace("mailto:", ""),
		}) // strip HTML tags

		let isFieldInvalid = false

		const doc: any = document
		const elem = doc.querySelector("[name='" + key + "']:valid")
		if (elem === null) {
			isFieldInvalid = true
		}

		// check for price maximum
		if (key === "purchaseAmount") {
			const price = parseFloat(e.target.value.replace(",", "."))
			if (price > MAX_PRODUCT_PRICE) {
				isFieldInvalid = true
			}
		}

		if (key == "birthday") {
			const birthday = new Date(e.target.value)
			const today = new Date()
			var age = today.getFullYear() - birthday.getFullYear()
			const m = today.getMonth() - birthday.getMonth()
			if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
				age--
			}
			if (age < 18) {
				isFieldInvalid = true
			}
		}

		// check validation
		setValidation({ ...validation, ["invalid_" + key]: isFieldInvalid })
	}

	function submitOrder() {
		// create order part
		const orderSuccessCallback = extId => {
			setIsLoading(false)
			if (extId) {
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "particpate", "form-submit": false, field_error: "send" })
				} catch (e) {}

				navigate("/danke")
			} else {
				setFormError(ERRORTYPES.SERVER)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
				} catch (e) {}
			}
		}
		const orderErrCallback = (tooManyAttemps: false, invalidCaptcha: false) => {
			setIsLoading(false)

			//TODO: Handling needs to be switched to error codes (BE part still open)
			if (tooManyAttemps) {
				setFormError(ERRORTYPES.MAXTRIES)
			} else if (invalidCaptcha) {
				setValidation({ ...validation, invalid_captcha: true })
				setFormError(null)
			} else {
				setFormError(ERRORTYPES.SERVER)
			}
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
			} catch (e) {}
		}

		if (allInputFieldsValid()) {
			if (globalState.receiptReference) {
				setIsLoading(true)
				setFormError(null)
				placeOrder(globalState, setFormValidation, orderSuccessCallback, orderErrCallback)
			} else {
				setFormError(ERRORTYPES.UPLOADSMISSING)
			}
		} else {
			setFormError(ERRORTYPES.FORMVALIDATION)
			scrollToError()
		}
	}

	function allInputFieldsValid() {
		// input names has to fit to variable classNames
		let isAllValid = true
		let invalidFields = {}

		// Special handing for bic. Bic is only for non DE IBANs required
		let fieldsToCheck: any = []
		fieldsToCheck = Object.keys(globalState).filter(e => e !== "package" && e !== "captcha" && e !== "captchaIdent" && e !== "receiptReference" && e !== "receiptFileName")

		for (let index = 0; index < fieldsToCheck.length; index++) {
			const key = fieldsToCheck[index]
			const doc: any = document
			const elem = doc.querySelector("[name='" + key + "']:valid")
			if (elem === null && key != "age") {
				isAllValid = false
				invalidFields["invalid_" + key] = true
				console.log(key)
			}
		}

		if (Object.keys(invalidFields).length) {
			setValidation({ ...validation, ...invalidFields })
		}

		return isAllValid
	}

	function setFormValidation(data: {}) {
		setValidation({
			...validation,
			...data,
		})
		scrollToError()
	}

	function scrollToError() {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-formerror").getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY - 120,
					left: 0,
					behavior: "smooth",
				})
			} catch (error) {}
		}, 500)
	}

	return (
		<RedSection>
			<form
				onSubmit={e => {
					e.preventDefault()
					submitOrder()
				}}
				noValidate={true}
				className=""
			>
				<div className="grid grid-cols-1 ">
					<div>
						<div className="sogehts  mb-4 text-white md:mb-24 lg:mt-4">
							<p className="mt-12 bg-yellow font-Roboto font-bold text-red lg:mb-24">3</p>
							<span className="mb-2 px-4 text-center font-Roboto text-[24px] uppercase text-yellow lg:mt-12 lg:text-[42px]">Trage deine persönlichen Daten ein:</span>
						</div>
						<div className="p-6 lg:p-0">
							<GoogleReCaptchaProvider language="de" useRecaptchaNet={true} reCaptchaKey="6Ld3q64pAAAAANaAsnU725wdMbv4Hy59gPwAjcnx">
								<PreFillForm {...{ formElementChanged }} />
								<Person {...{ validation, formElementChanged }} />
								<Legal {...{ validation, formElementChanged }} />
							</GoogleReCaptchaProvider>
						</div>

						<div className="pt-8 pb-2" ref={animationRef}>
							<div className="">
								<div className="relative px-4 text-center">
									<div className="relative inline-block">
										<button
											type="submit"
											className="block w-[100%] rounded bg-yellow p-4 font-Roboto text-2xl font-bold uppercase text-red text-red sm:w-[auto] lg:inline-block lg:py-8  lg:px-6 lg:text-[52px]"
											disabled={isLoading ? true : undefined}
										>
											{isLoading && <Spinner />}
											jetzt teilnehmen!
										</button>
										<img
											src={process.env.PUBLIC_URL + "/images/knossi/arrow_pink.png"}
											className="absolute -right-[40px] bottom-[48px] mt-4 w-[80px] lg:-right-[90px] lg:bottom-[60px] lg:w-[160px]"
										/>
									</div>
									{formError && <Alert className={"my-4 font-bold text-errorred"}>{formError}</Alert>}
								</div>
							</div>
						</div>
						<p className="mt-4 mb-0 text-center font-Roboto text-4xl text-[50px] font-bold uppercase leading-[80px] text-yellow md:mt-12 lg:text-[100px]">POMAIKA’I</p>
						<p className="mt-0 mb-12 text-center font-Roboto text-4xl text-[24px] font-bold uppercase leading-[32px] text-yellow md:leading-[52px] lg:text-[32px]">(Viel GLück)</p>
					</div>
				</div>
			</form>
		</RedSection>
	)
}

export default Form
