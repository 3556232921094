const domains = {
	"www.gewinnspiel-hawaiian-tropic.de": {
		env: "prod",
		apibase: "/",
		recaptchakey: "6Ld3q64pAAAAANaAsnU725wdMbv4Hy59gPwAjcnx",
	},
	"gewinnspiel-hawaiian-tropic.de": {
		env: "prod",
		apibase: "/",
		recaptchakey: "6Ld3q64pAAAAAMlZRXoW0gFehBAdoRMrmAllGgSq",
	},

	"prod.fe.hawaiian-tropic.campaign-loyalty.com": {
		env: "prod",
		apibase: "/",
		recaptchakey: "6Ld3q64pAAAAANaAsnU725wdMbv4Hy59gPwAjcnx",
	},

	"int.fe.hawaiian-tropic.campaign-loyalty.com": {
		env: "stage",
		apibase: "/inte/",
		recaptchakey: "6Ld3q64pAAAAANaAsnU725wdMbv4Hy59gPwAjcnx",
	},
	localhost: {
		env: "local",
		apibase: "/inte/",
		recaptchakey: "6Ld3q64pAAAAANaAsnU725wdMbv4Hy59gPwAjcnx",
	},
	"localhost:3000": {
		env: "local",
		apibase: "/inte/",
		recaptchakey: "6Ld3q64pAAAAANaAsnU725wdMbv4Hy59gPwAjcnx",
	},
}
export const getEnvConfig = () => {
	return domains[window.location.host] || { apibase: "", recaptchakey: "6Ld3q64pAAAAANaAsnU725wdMbv4Hy59gPwAjcnx" }
}
