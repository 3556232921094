import { Link } from "react-router-dom"
import React from "react"

function Footer() {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	return (
		<footer className="relative mx-auto flex w-full flex-col items-center  bg-green  lg:z-[600]">
			<section className="pt-16">
				<div className="px-2 text-center lg:px-32 ">
					<h2 className="mx-auto max-w-[1300px] font-Roboto text-[24px] font-bold uppercase leading-[24px] text-yellow  lg:text-[58px] lg:leading-[70px]">
						Lust auf weitere spannende Neuigkeiten zu Hawaiian Tropic?
					</h2>
					<div className="relative grid grid-cols-1 gap-12 self-center justify-self-center xl:grid-cols-[20%_60%_20%]">
						<div className="hidden xl:block">
							{" "}
							<img src={"/images/footer-left-bg.png"} className="absolute left-0 top-0 w-[300px]" />
						</div>
						<p className="mx-auto mt-8 font-RobotoNormal text-[16px] text-yellow md:max-w-[100%] lg:text-xl ">
							Dann folge uns doch auf Instagram unter{" "}
							<a href="https://www.instagram.com/hawaiiantropic_de/" className="underline" target="_blank">
								hawaiiantropic_de
							</a>
							<br /> auf unserer Webseite{" "}
							<a href="https://hawaiian-tropic.de/" className="underline" target="_blank">
								{" "}
								www.hawaiian-tropic.de
							</a>
							<br /> oder auf TikTok:{" "}
							<a href="https://www.tiktok.com/@hawaiiantropic_de" className="underline" target="_blank">
								https://www.tiktok.com/@hawaiiantropic_de
							</a>{" "}
						</p>
						<div className="hidden xl:block">
							{" "}
							<img src={"/images/footer-right-bg.png"} className="absolute right-0 top-[0px] w-[180px]" />
						</div>
					</div>
				</div>
				<div className="align-self-center mx-auto mb-12 max-w-[220px] justify-center self-center  pt-8 md:mb-24 md:max-w-[250px]">
					<a
						href="https://www.instagram.com/hawaiiantropic_de/"
						target="_blank"
						className="inline-block pr-8 md:max-w-[116px] lg:pt-0"
						rel="noreferrer"
						onClick={() => track("Instagram")}
					>
						<img src={"/images/knossi/instagram.png"} alt="Instagram" width="116" height="116" className="h-auto max-w-[50px] flex-shrink-0 md:max-w-[60px]" />
					</a>
					<a href="https://hawaiian-tropic.de/" target="_blank" className="inline-block pr-8 md:max-w-[116px] lg:pt-0" rel="noreferrer" onClick={() => track("website")}>
						<img src={"/images/knossi/website.png"} alt="Instagram" width="116" height="116" className="h-auto max-w-[50px] flex-shrink-0 md:max-w-[60px]" />
					</a>

					<a href="https://www.tiktok.com/@hawaiiantropic_de" className="inline-block" target="_blank" rel="noreferrer" onClick={() => track("TikTok")}>
						<img src={"/images/knossi/tiktok.png"} alt="Tiktok" width="116" height="116" className="h-auto max-w-[50px] md:max-w-[60px]" />
					</a>
				</div>{" "}
			</section>
			<div className="mt-8 flex w-full flex-col items-center justify-center bg-red py-4  lg:mt-0 lg:flex-row">
				<Link to={"/teilnahmebedingungen"} className="text-base text-white" onClick={() => track("Teilnahmebedingungen")}>
					Teilnahmebedingungen
				</Link>
				<span className="hidden px-4 text-base text-white lg:block">|</span>
				<Link to={"/faq"} className="text-base text-white" onClick={() => track("FAQ")}>
					FAQ
				</Link>
				<span className="hidden px-4 text-base text-white lg:block">|</span>
				<a href="https://hawaiian-tropic.de/pages/datenschutz" target="_blank" className="text-base text-white">
					Datenschutzerklärung
				</a>
				<span className="hidden px-4 text-base text-white lg:block">|</span>
				<Link to={"/kontakt"} className="text-base text-white" onClick={() => track("Kontakt")}>
					Kontakt
				</Link>
				<span className="hidden px-4 text-base text-white lg:block">|</span>
				<Link to={"/impressum"} className="text-base text-white" onClick={() => track("Impressum")}>
					Impressum
				</Link>
			</div>
		</footer>
	)
}

export default Footer
