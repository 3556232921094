import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import Form from "../components/Forms/Form"
import Sogehts from "../components/home/Sogehts"
import Header from "../components/Header"

const Home: React.FC = () => {
	// SEO Meta Tags

	const meta = {
		title: "Hawaiian Tropic Gewinnspiel",
		canonical: "https://arvato-hawaiian-tropic-2023.de/",
		meta: {
			name: {
				robots: "noindex, nofollow",
			},
		},
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])
	return (
		<DocumentMeta {...meta}>
			<Header page="start" />
			<Sogehts />
			<Form />
		</DocumentMeta>
	)
}
export default Home
