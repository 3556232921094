import React from "react"
import { useContext } from "react"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import ElemSelect from "../elements/Select"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
interface PersonProps {
	formElementChanged: Function
	validation: Object
}
const Person: React.FC<PersonProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)

	return (
		<section className="mx-auto max-w-[900px]">
			<div className="mx-auto mb-3 grid  grid-cols-1 items-start gap-2 lg:grid-cols-[1fr_1fr]">
				<ElemInput
					label={"Vorname*"}
					props={{
						type: "text",
						name: "firstname",
						value: globalState.firstname,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "firstname"),
					}}
					hasError={validation["invalid_firstname"] ? true : false}
					className="col-span-2 lg:col-span-1"
					errMsg={validation["invalid_firstname_errMsg"] || "Bitte Vornamen eingeben."}
				/>
				<ElemInput
					label={"Name*"}
					props={{
						type: "text",
						name: "surname",
						value: globalState.surname,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "surname"),
					}}
					hasError={validation["invalid_surname"] ? true : false}
					className="col-span-2 lg:col-span-1"
					errMsg={validation["invalid_surname_errMsg"] || "Bitte Nachnamen eingeben."}
				/>
			</div>
			<div className="mx-auto mb-3 grid grid-cols-1 items-start gap-2 lg:grid-cols-[70%_30%]">
				<ElemInput
					className=""
					label={"Straße*"}
					props={{
						type: "text",
						name: "street",
						value: globalState.street,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "street"),
					}}
					hasError={validation["invalid_street"] ? true : false}
					errMsg={validation["invalid_street_errMsg"] || "Bitte Straße angeben."}
				/>
				<ElemInput
					className=""
					label={"Hausnummer*"}
					props={{
						type: "text",
						name: "housenr",
						value: globalState.housenr,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "housenr"),
					}}
					hasError={validation["invalid_housenr"] ? true : false}
					errMsg={validation["invalid_housenr_errMsg"] || "Bitte Hausnummer angeben."}
				/>
			</div>
			<div className="mx-auto mb-3 grid grid-cols-1 items-start gap-2 lg:grid-cols-[19%_80%]">
				<ElemInput
					className=""
					label={"PLZ*"}
					props={{
						type: "text",
						name: "zip",
						value: globalState.zip,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "zip"),
					}}
					hasError={validation["invalid_zip"] ? true : false}
					errMsg={validation["invalid_zip_errMsg"] || "Bitte PLZ angeben."}
				/>
				<ElemInput
					className=""
					label={"Ort*"}
					props={{
						type: "text",
						name: "city",
						value: globalState.city,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "city"),
					}}
					hasError={validation["invalid_city"] ? true : false}
					errMsg={validation["invalid_city_errMsg"] || "Bitte Ort angeben."}
				/>
			</div>
			<div className="mx-auto mb-6 grid grid-cols-1 items-start gap-5 lg:grid-cols-[1fr_3fr]">
				<ElemInput
					className="col-span-2 lg:col-span-1"
					label={"Geburtstag* (min. 18 Jahre)"}
					props={{
						type: "date",
						name: "birthday",
						value: globalState.birthday,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "birthday"),
					}}
					hasError={validation["invalid_birthday"] ? true : false}
					errMsg={validation["invalid_birthday_errMsg"] || "Bitte angeben (min. 18 Jahre)."}
				/>
				<ElemInput
					className="col-span-2 lg:col-span-1"
					label={"E-Mail-Adresse*"}
					props={{
						type: "email",
						name: "email",
						value: globalState.email,
						required: "required",
						minLength: 1,
						onChange: e => formElementChanged(e, "email"),
					}}
					hasError={validation["invalid_email"] ? true : false}
					errMsg={validation["invalid_email_errMsg"] || "Bitte E-Mail-Adresse eingeben."}
				/>
			</div>
			<p className="mb-8 border-2 border-yellow p-4 text-yellow">
				Bitte hier deine korrekte Adresse hinterlegen, da diese für den Versand der Gewinne genutzt wird. Bei Falschangaben können wir leider keine Zustellung garantieren.
			</p>
			<div className="mx-auto mb-12 grid items-start">
				<p className="mb-2 text-yellow">Hier habe ich mein Hawaiian Tropic Produkt gekauft*</p>
				<ElemSelect
					className="col-span-2 lg:col-span-1"
					label={""}
					props={{
						name: "shop",
						value: globalState.shop,
						required: "required",
						onChange: e => formElementChanged(e, "shop"),
					}}
					hasError={validation["invalid_shop"] ? true : false}
					errMsg={validation["invalid_email_errMsg"] || "Bitte auswählen."}
				>
					<option value="">Bitte auswählen</option>
					<option value="A">in einer dm Filiale vor Ort</option>
					<option value="B">online auf www.dm.de </option>
				</ElemSelect>
			</div>
		</section>
	)
}

export default Person
