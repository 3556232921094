import { useState } from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import MobileMenu from "./MobileMenu"

const Header = ({ isPromoOver = false, showPrescreen = false, page = "" }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	function scrollToAnchor(anchor) {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-" + anchor).getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY,
					left: 0,
					behavior: "smooth",
				})

				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "header", link: anchor })
				} catch (e) {}
			} catch (error) {}
		}, 300)
	}

	return (
		<>
			<div ref={animationRef}>{showMenu && <MobileMenu setShowMenu={setShowMenu} isPromoOver={isPromoOver} scrollToAnchor={scrollToAnchor} />}</div>
			{page === "start" ? (
				<header className="mx-auto h-[600px] bg-yellow bg-[url('/src/bgimages/header_bg_mobile_startpage.jpg')] bg-contain bg-[center_top]  bg-no-repeat md:bg-[url('/src/bgimages/header_bg_startpage.jpg')] lg:h-[650px] lg:bg-contain"></header>
			) : (
				<header className="mx-auto h-[560px] bg-yellow bg-[url('/src/bgimages/header_bg_mobile.jpg')] bg-contain bg-[center_top]   bg-no-repeat md:bg-[url('/src/bgimages/header_bg.png')] lg:h-[650px] lg:bg-contain"></header>
			)}
		</>
	)
}

export default Header
