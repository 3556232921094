import React, { useEffect } from "react"
import Header from "../components/Header"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<Header />
			<div className=" mx-auto mt-12 mb-12 px-6 text-center text-red lg:mt-24 lg:mb-24 lg:mt-6 lg:max-w-[850px] lg:pr-6">
				<h2 className="mb-4 text-[22px] font-bold uppercase leading-[28px] text-red lg:mb-8 lg:text-[52px] lg:leading-[58px]">Vielen Dank für deine Teilnahme!</h2>
				<p className="mb-4 text-[16px] text-red lg:text-[24px]">
					Deine Teilnahme ist erfolgreich bei uns eingegangen. <br />
					<br />
					Die Ermittlung der Gewinner erfolgt innerhalb von zwei Wochen nach Aktionsende per Zufallsziehung aus allen gültigen Teilnahmen. <br />
					<br />
					Die Gewinner werden per E-Mail über ihren Gewinn informiert. Solltest du nach Ablauf des Zeitraums keine E-Mail von uns erhalten haben, bist du leider nicht unter den
					Gewinnern.
				</p>
				<p className="mb-4 text-[16px] text-red lg:text-[24px]">
					Wir wünschen dir viel Glück.
					<br />
					Dein Team von Hawaiian Tropic
				</p>
			</div>
		</>
	)
}
export default Confirm
