import { useContext } from "react"
import { Link } from "react-router-dom"
import { Context } from "../../store/store"
import ElemCheckbox from "../elements/Checkbox"

interface LegalProps {
	formElementChanged: Function
	validation: Object
}
const Legal: React.FC<LegalProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<div className=" mx-auto max-w-[800px] lg:mt-6">
			<ElemCheckbox
				label={
					<>
						Ich habe die{" "}
						<Link
							to="/teilnahmebedingungen"
							target="_blank"
							className="underline"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "form", link: "teilnahmebedingungen" })
								} catch (e) {}
							}}
						>
							Teilnahmebedingungen
						</Link>{" "}
						gelesen und akzeptiere sie.*
					</>
				}
				props={{
					type: "checkbox",
					name: "terms",
					required: "required",
					value: globalState.terms,
					onClick: e => formElementChanged(e, "terms"),
				}}
				hasError={validation["invalid_terms"] ? true : false}
				className="pb-6"
				errMsg={validation["invalid_terms_errMsg"] || "Bitte Teilnahmebedingungen bestätigen."}
			/>
			<ElemCheckbox
				label={
					<>
						Ich habe die{" "}
						<a
							href="https://hawaiian-tropic.de/pages/datenschutz"
							target="_blank"
							className="underline"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "form", link: "datenschutz" })
								} catch (e) {}
							}}
						>
							Datenschutzbestimmungen
						</a>{" "}
						gelesen und akzeptiere sie.*
					</>
				}
				props={{
					type: "checkbox",
					name: "privacy",
					required: "required",
					value: globalState.privacy,
					onClick: e => formElementChanged(e, "privacy"),
				}}
				hasError={validation["invalid_privacy"] ? true : false}
				className="pb-6"
				errMsg={validation["invalid_terms_errMsg"] || "Bitte Datenschutzbestimmungen bestätigen."}
			/>
			<p className="pl-11 text-white">* Pflichtfeld</p>
		</div>
	)
}

export default Legal
