import React, { useEffect } from "react"
import Header from "../components/Header"
import { Link } from "react-router-dom"

const Error: React.FC = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<Header />
			<h2 className="h2 mt-12 text-center text-red">Diese Seite existiert nicht...</h2>
			<div className="relative z-0 mt-12 px-4 pt-0 pb-16 text-center text-xl text-red">Hier scheint etwas schief gelaufen zu sein....</div>
			<div className="py-10 pt-5 text-center text-xl text-red">
				<Link to="/" className="btn btn-hero mt-8 rounded bg-red px-12 py-4 text-white">
					Zurück
				</Link>
			</div>
		</>
	)
}
export default Error
