import React, { useEffect } from "react"
import Header from "../components/Header"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<Header />
			<div className=" mx-auto mb-12 px-6 text-center text-red lg:mb-24 lg:mt-6 lg:max-w-[850px] lg:pr-6">
				{" "}
				<h2 className="mb-4 mt-12 text-[22px] font-bold uppercase leading-[24px] text-red lg:mb-8 lg:text-[52px] lg:leading-[58px]">
					VIELEN DANK FÜR DEIN INTERESSE AN UNSEREM GEWINNSPIEL.
				</h2>
				<p className="mb-4 text-[16px] text-red lg:text-[24px]">
					Leider ist der Teilnahmezeitraum abgelaufen. Besuche uns weiterhin auf{" "}
					<a href="https://www.hawaiian-tropic.de" className="underline" target="_blank">
						www.hawaiian-tropic.de
					</a>
					, auf{" "}
					<a href="https://www.instagram.com/hawaiiantropic_de/" className="underline" target="_blank">
						Instagram
					</a>{" "}
					oder{" "}
					<a href="http://www.tiktok.com/@hawaiiantropic_de " className="underline" target="_blank">
						Tik Tok
					</a>{" "}
					und bleib auf dem Laufenden.
				</p>
			</div>
		</>
	)
}
export default Confirm
