import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	onClick?: any
	showLeftCount?: boolean
	hasError?: boolean
	suffix?: string
}

const ElemInput: React.FC<InputProps> = ({ className, props, label, onClick, errMsg, showLeftCount, hasError, suffix }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "")}>
			<input
				className={
					(props.type === "date" ? "text-green" : " peer ") +
					"text-whit w-full  appearance-none rounded bg-white py-2 px-4 text-lg shadow-inner outline-0 " +
					(hasError ? "  js-fosrmerror " : "") +
					(props.type === "date" ? " uppercase" : "")
				}
				onClick={onClick}
				{...props}
			/>
			{suffix && <div className="absolute top-6 right-3 text-red">{suffix}</div>}
			{showLeftCount && props.maxLength && (
				<div className="absolute top-6 right-3 text-red">
					{props.value.length}/{props.maxLength}
				</div>
			)}
			<div ref={animationRef}>{hasError && <div className="formErrorMsg pt-2 font-bold text-green">{errMsg}</div>}</div>
			<span className={"pointer-events-none block pb-2 pt-2 " + (hasError ? "text-yellow" : "text-yellow")}>{label}</span>
		</label>
	)
}

export default ElemInput

// https://play.tailwindcss.com/tKldzVn3U1
