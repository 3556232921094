import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"
import RosaSection from "../components/elements/BeigeSection"
import Header from "../components/Header"

function FAQ() {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// 	name: {
		// 		robots: "noindex, nofollow",
		// 	},
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta {...meta}>
			<Header />
			<div className="container mx-auto max-w-[800px] py-8 px-4 text-blue ">
				<h2 className="h2 mb-16 pt-4 text-center text-2xl text-red">FAQ </h2>
				<AccordionItem headline="Wie lange dauert die Aktion?" num="1.">
					<p className="text-red">
						Der Teilnahmezeitraum ist vom 15.05.2024 bis einschließlich 31.08.2024 23:59 Uhr deutscher Zeit. Danach ist eine Teilnahme an unserem Gewinnspiel nicht mehr möglich.
					</p>
				</AccordionItem>
				<AccordionItem headline="Welche Produkte nehmen an der Aktion teil?" num="2.">
					<>
						<p className="mb-6 text-red">Aktionsprodukte sind alle Produkte der Marke " Hawaiian Tropic ", die vom teilnehmenden Händler dm angeboten werden: </p>
						<ul className="mb-4 list-outside list-disc">
							<li className="mb-2">5099821001070 Hawaiian Tropic Tropical Tanning Oil Dark LSF 0, 200ml</li>
							<li className="mb-2">5099821001070 Hawaiian Tropic Glowing Oil LSF 0, 200ml</li>
							<li className="mb-2">5099821109219 Hawaiian Tropic Protective Dry Spray Oil LSF 30, 200ml</li>
							<li className="mb-2">5099821109219 Hawaiian Tropic Glowing Protection Dry Spray Oil Mist LSF 30, 200ml</li>
							<li className="mb-2">5099821001834 Hawaiian Tropic Satin Protection Sun Lotion LSF 30, 180ml</li>
							<li className="mb-2">5099821001834 Hawaiian Tropic Glowing Protection Lotion LSF 30, 180ml</li>
							<li className="mb-2">5099821001858 Hawaiian Tropic Satin Protection Sun Lotion LSF 50, 180ml</li>
							<li className="mb-2">5099821001858 Hawaiian Tropic Glowing Protection Lotion LSF 50, 180ml</li>
							<li className="mb-2">5099821002039 Hawaiian Tropic Silk Hydration After Sun Lotion, 180ml</li>
							<li className="mb-2">5099821002039 Hawaiian Tropic Hydrating After Sun Lotion, 180ml </li>
							<li className="mb-2">5099821001919 Hawaiian Tropic Silk Hydration Air Soft Face Lotion LSF 30, 50ml</li>
							<li className="mb-2">5099821001919 Hawaiian Tropic Silk Hydrating Protection Face Lotion SPF 30, 50ml </li>
							<li className="mb-2">5099821001414 Hawaiian Tropic Silk Hydration Sun Lotion LSF 30, 180ml</li>
							<li className="mb-2">5099821137144 Hawaiian Tropic Hydrating Protection Lotion SPF 30, 180ml</li>
							<li className="mb-2">5099821001421 Hawaiian Tropic Silk Hydration Sun Lotion LSF 50, 180ml (Artikel nicht mehr erhältlich. Ggf. Restbestände)</li>
							<li className="mb-2">5099821132316 Hawaiian Tropic Mineral Powder Brush LSF 30, 4,25g</li>
						</ul>
					</>
				</AccordionItem>
				<AccordionItem headline="Wie kann ich an der Aktion teilnehmen?" num="3.">
					<p className="mb-6 text-red">
						Um am Gewinnspiel teilzunehmen, ist der Kauf eines Aktionsproduktes in der Filiale des teilnehmenden Händlers dm oder über dessen Online-Shop{" "}
						<a href="http://www.dm.de" className="underline" target="_blank">
							http://www.dm.de
						</a>{" "}
						im Zeitraum 15.05.2024 bis 31.08.2024 um 23:59 Uhr deutscher Zeit notwendig.
					</p>
					<p className="mb-6 text-red">
						Die Teilnahme an der Verlosung ist ausschließlich online über die Aktionswebsite{" "}
						<a href="http://www.gewinnspiel-hawaiian-tropic.de" className="underline" target="_blank">
							www.gewinnspiel-hawaiian-tropic.de
						</a>{" "}
						möglich. Teilnahmeschluss ist der 31.08.2024 um 23:59 Uhr deutscher Zeit. Um teilzunehmen, muss ein Foto des vollständigen Rechnungsbelegs auf der Aktionsseite{" "}
						<a href="http://www.gewinnspiel-hawaiian-tropic.de" className="underline" target="_blank">
							www.gewinnspiel-hawaiian-tropic.de
						</a>{" "}
						hochgeladen sowie das Teilnahmeformular vollständig ausgefüllt und abgeschickt werden.
					</p>
					<p className="mb-6 text-red">
						Die Ermittlung der Gewinner erfolgt innerhalb von zwei Wochen nach Aktionsende per Zufallsziehung aus allen gültigen Teilnahmen. Die Gewinner werden per E-Mail über
						ihren Gewinn informiert und müssen innerhalb von 14 Tagen formlos (per E-Mail) die Gewinnannahme bestätigen. Meldet sich der Teilnehmer nicht zurück, so verfällt der
						Gewinnanspruch und ein neuer Gewinner wird ermittelt.
					</p>
					<p className="mb-6 text-red">
						Die Gewinner sollten außerdem den originalen Rechnungsbeleg aufbewahren und ihn der Wilkinson Sword GmbH auf Wunsch zur abschließenden Prüfung zur Verfügung stellen
						(s.u.).{" "}
					</p>
				</AccordionItem>
				<AccordionItem headline="Kommen ggf. Kosten auf mich zu?" num="4.">
					<>
						<p className="mb-6 text-red">
							Für die Teilnahme sind ein Internetzugang sowie der Kauf eines Aktionsprodukts beim teilnehmenden Händler dm erforderlich. Der Gewinner des Hauptgewinns trägt die
							Kosten für die Einsendung des originalen Rechnungsbelegs. Darüber hinaus entstehen keine weiteren Kosten.
						</p>
					</>
				</AccordionItem>
				<AccordionItem headline="Was gibt es zu gewinnen?" num="5.">
					<p className="mb-6 text-red">
						Im Rahmen dieser Gewinnspielaktion wird eine Reise nach Hawaii im Wert von bis zu 10.000€ für 2 Personen mit 13 Übernachtungen inkl. Flügen und Hoteltransfer verlost;
						der Einlöse Zeitraum beträgt 2 Jahre.
					</p>
					<p className="mb-6 text-red">
						Des Weiteren werden 5 x Surfboards, 200 x Hawaiian Tropic Produktpakete im Wert von je 20 € und 500 x dm-Gutscheine im Wert von je 10 € verlost.
					</p>
					<p className="mb-6 text-red">
						Eine Barauszahlung der Gewinne ist nicht möglich. Gewinnansprüche können nicht auf andere Personen übertragen oder abgetreten werden. Der Rechtsweg ist ausgeschlossen.
					</p>
				</AccordionItem>
				<AccordionItem headline="Wer kann am Gewinnspiel teilnehmen?" num="6.">
					<p className="text-red">
						Teilnahmeberechtigt sind alle Personen mit Wohnsitz in Deutschland, die mindestens 18 Jahre alt sind. Von der Teilnahme ausgeschlossen sind Mitarbeiter der Wilkinson
						Sword GmbH. Der Versand der Gewinne ins Ausland ist nicht möglich.
					</p>
				</AccordionItem>
				<AccordionItem headline="Wie oft kann ich an der Aktion teilnehmen?" num="7.">
					<p className="text-red">Eine Mehrfachteilnahme ist möglich, d. h. jeder Teilnehmer kann jeweils mit neuem Rechnungsbeleg an der Aktion teilnehmen.</p>
				</AccordionItem>
				<AccordionItem headline="Wird der Original-Rechnungsbeleg benötigt?" num="8.">
					<p className="mb-6 text-red">
						Der Gewinnanspruch auf den Hauptgewinn (Reise nach Hawaii für 2 Personen im Wert von bis zu 10.000 €) entsteht erst, nachdem die Wilkinson Sword GmbH den originalen
						Rechnungsbeleg erhalten und geprüft hat. Teilnehmer sind verpflichtet, den originalen Rechnungsbeleg aufzubewahren und ihn der Wilkinson Sword GmbH zur abschließenden
						Prüfung zur Verfügung zu stellen. Zur Einsendung des originalen Rechnungsbelegs setzt sich die Wilkinson Sword GmbH oder eine von Wilkinson beauftragte Agentur per
						E-Mail mit dem Gewinner in Verbindung. Geht der originale Rechnungsbeleg verloren, wird keine Haftung übernommen, daher empfehlen wir den Versand per versichertem
						Einschreiben. Nach Eingang und Prüfung des Rechnungsbelegs werden alle für den Empfang des Hauptgewinns notwendigen Details von einer Wilkinson-Agentur mit dem Gewinner
						besprochen. Auch bei den anderen Gewinnen empfehlen wir, den originalen Rechnungsbeleg bis zum Erhalt des Gewinns aufzubewahren. Nach Erhalt des Gewinns wird der
						Rechnungsbeleg nicht mehr benötigt.
					</p>
				</AccordionItem>
				<AccordionItem headline="Du hast keine Antwort auf deine Frage erhalten?" num="9.">
					<p className="mb-6 text-red">
						Das Service-Team ist per E-Mail unter{" "}
						<a href="mailto:info@gewinnspiel-hawaiian-tropic.de" className="underline">
							info@gewinnspiel-hawaiian-tropic.de
						</a>{" "}
						erreichbar.
					</p>
				</AccordionItem>
			</div>
			<div className="py-10 pt-5 text-center text-xl text-red">
				<button onClick={() => navigate(-1)} className="btn btn-hero mt-8 rounded bg-red px-12 py-4 text-white">
					Zurück
				</button>
			</div>
		</DocumentMeta>
	)
}
export default FAQ
