import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Header from "../components/Header"

const Terms: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			// behavior: "smooth",
		})
	}, [])

	return (
		<>
			<Header />
			<div className="container mx-auto max-w-[800px] py-8 px-4 text-red">
				<h2 className="h2 mb-16 pt-4 text-center text-2xl text-red">Teilnahme&shy;bedingungen</h2>
				<p className="mb-8 text-center text-xl">
					<strong>
						Hawaiian Tropic Gewinnspiel 2024
						<br />
					</strong>
				</p>
				<p className="mb-4 text-center">
					<strong>
						Mit der Teilnahme an der Aktion akzeptiert der Teilnehmer die folgenden Teilnahmebedingungen:
						<br />
					</strong>
				</p>

				<h3 className="h3 py-4">1. Veranstalter</h3>
				<p className="mb-4">
					Die Wilkinson Sword GmbH, Schützenstraße 110, D-42659 Solingen (im Folgenden „Veranstalter" genannt) veranstaltet in der Zeit vom 15.05.2024 bis 31.08.2024 mit der Marke
					Hawaiian Tropic eine befristete Gewinnspielpromotion mit Verlosung. Die Abwicklung der Aktion erfolgt durch die Magellan GmbH, Oststraße 10, D-40211 Düsseldorf und die
					direct services Gütersloh GmbH, Reinhard-Mohn-Str. 300, D-33333 Gütersloh.
				</p>

				<h3 className="h3 py-4">2. Teilnahmeberechtigung</h3>
				<p className="mb-4">
					Teilnehmen dürfen natürliche Personen ab 18 Jahren mit Wohnsitz in Deutschland. Mitarbeiter des Veranstalters sind nicht teilnahmeberechtigt. Außerdem behält sich der
					Veranstalter das Recht vor, Personen von der Teilnahme auszuschließen, bei denen der Verdacht besteht, dass sie sich bei der Teilnahme unerlaubter Hilfsmittel bedienen
					oder in sonstiger Weise versuchen, sich oder Dritten durch Manipulation Vorteile zu verschaffen. Der Ausschluss kann in diesem Fall auch nach der Teilnahme erfolgen. Der
					Veranstalter hat das Recht die Gewinnausschüttung zu verweigern bzw. den Gewinn zurückzufordern.
				</p>

				<h3 className="h3 py-4">3. Teilnahmevoraussetzung / Aktionsprodukte</h3>
				<p className="mb-4">
					Voraussetzung für die Teilnahme an der Verlosung ist der Kauf eines Aktionsproduktes in der Filiale des teilnehmenden Händlers dm oder über dessen Online-Shop{" "}
					<a href="http://www.dm.de" className="underline" target="_blank">
						http://www.dm.de
					</a>{" "}
					im Zeitraum 15.05.2024 bis 31.08.2024 um 23:59 Uhr deutscher Zeit. Aktionsprodukte sind alle Produkte der Marke "Hawaiian Tropic", die vom teilnehmenden Händler dm
					angeboten werden:
					<ul className="mt-4 mb-4 ml-6 list-disc">
						<li className="mb-2">5099821001070 Hawaiian Tropic Tropical Tanning Oil Dark LSF 0, 200ml</li>
						<li className="mb-2">5099821001070 Hawaiian Tropic Glowing Oil LSF 0, 200ml</li>
						<li className="mb-2">5099821109219 Hawaiian Tropic Protective Dry Spray Oil LSF 30, 200ml</li>
						<li className="mb-2">5099821109219 Hawaiian Tropic Glowing Protection Dry Spray Oil Mist LSF 30, 200ml</li>
						<li className="mb-2">5099821001834 Hawaiian Tropic Satin Protection Sun Lotion LSF 30, 180ml</li>
						<li className="mb-2">5099821001834 Hawaiian Tropic Glowing Protection Lotion LSF 30, 180ml</li>
						<li className="mb-2">5099821001858 Hawaiian Tropic Satin Protection Sun Lotion LSF 50, 180ml</li>
						<li className="mb-2">5099821001858 Hawaiian Tropic Glowing Protection Lotion LSF 50, 180ml</li>
						<li className="mb-2">5099821002039 Hawaiian Tropic Silk Hydration After Sun Lotion, 180ml</li>
						<li className="mb-2">5099821002039 Hawaiian Tropic Hydrating After Sun Lotion, 180ml </li>
						<li className="mb-2">5099821001919 Hawaiian Tropic Silk Hydration Air Soft Face Lotion LSF 30, 50ml</li>
						<li className="mb-2">5099821001919 Hawaiian Tropic Silk Hydrating Protection Face Lotion SPF 30, 50ml </li>
						<li className="mb-2">5099821001414 Hawaiian Tropic Silk Hydration Sun Lotion LSF 30, 180ml</li>
						<li className="mb-2">5099821137144 Hawaiian Tropic Hydrating Protection Lotion SPF 30, 180ml</li>
						<li className="mb-2">5099821001421 Hawaiian Tropic Silk Hydration Sun Lotion LSF 50, 180ml (Artikel nicht mehr erhältlich. Ggf. Restbestände)</li>
						<li className="mb-2">5099821132316 Hawaiian Tropic Mineral Powder Brush LSF 30, 4,25g</li>
					</ul>
				</p>
				<p className="mb-4">
					Die Teilnahmeberechtigung ergibt sich aus dem jeweiligen Rechnungsbeleg, auf dem das Aktionsprodukt aufgeführt ist. Jeder Rechnungsbeleg darf nur einmal zur Teilnahme am
					Gewinnspiel verwendet werden und stellt somit nur eine Gewinnchance dar, auch wenn mehrere Produkte gekauft wurden.
				</p>
				<h3 className="h3 py-4">4. Teilnahme & Gewinnbenachrichtigung</h3>
				<p className="mb-4">
					Die Teilnahme an der Verlosung ist ausschließlich online über die Aktionswebsite{" "}
					<a href="http://www.gewinnspiel-hawaiian-tropic.de" className="underline" target="_blank">
						www.gewinnspiel-hawaiian-tropic.de
					</a>{" "}
					möglich. Teilnahmeschluss ist der 31.08.2024 um 23:59 Uhr deutscher Zeit. Um teilzunehmen, muss ein Foto des vollständigen Rechnungsbelegs hochgeladen sowie das
					Teilnahmeformular vollständig ausgefüllt und abgeschickt werden. Die Teilnehmer können ihre Teilnahme jederzeit per E-Mail an{" "}
					<a href="mailto:info@gewinnspiel-hawaiian-tropic.de" className="underline" target="_blank">
						info@gewinnspiel-hawaiian-tropic.de
					</a>{" "}
					widerrufen. Der Rechtsweg ist ausgeschlossen. Die Ermittlung der Gewinner erfolgt innerhalb von zwei Wochen nach Aktionsende per Zufallsziehung aus allen gültigen
					Teilnahmen. Die Gewinner werden per E-Mail über ihren Gewinn sowie die Zustelldauer informiert und müssen formlos (per E-Mail) die Gewinnannahme bestätigen. Nimmt der
					Teilnehmer den Gewinn nicht an oder meldet sich nach zweimaliger Aufforderung innerhalb eines Monats nicht zurück, so verfällt der Gewinnanspruch und ein neuer Gewinner
					wird ermittelt. Der Gewinnanspruch auf den Hauptgewinn (Reise nach Hawaii für 2 Personen im Wert von bis zu 10.000 €) entsteht erst nachdem die Wilkinson Sword GmbH den
					originalen Rechnungsbeleg erhalten und diesen geprüft hat. Teilnehmer sind verpflichtet den originalen Rechnungsbeleg aufzubewahren und ihn der Wilkinson Sword GmbH zur
					abschließenden Prüfung zur Verfügung zu stellen. Zur Übermittlung des originalen Rechnungsbelegs setzt sich die Wilkinson Sword GmbH oder eine von Wilkinson beauftragte
					Agentur per E-Mail mit dem Gewinner in Verbindung. Geht der originale Rechnungsbeleg verloren, wird keine Haftung übernommen, daher empfehlen wir den Versand per
					versichertem Einschreiben. Nach Eingang und Prüfung des Rechnungsbelegs werden alle für den Empfang des Hauptgewinns notwendigen Details von einer Wilkinson-Agentur mit
					dem Gewinner besprochen. Auch bei den anderen Gewinnen empfehlen wir, den originalen Rechnungsbeleg bis zum Erhalt des Gewinns aufzubewahren. Nach Erhalt des Gewinns wird
					der Rechnungsbeleg nicht mehr benötigt. Der Gewinner des Hauptgewinns trägt die Kosten für die Einsendung des originalen Rechnungsbelegs.
				</p>
				<h3 className="h3 py-4">5. Gewinne</h3>
				<p className="mb-4">
					Im Rahmen dieser Gewinnspielaktion wird eine Reise nach Hawaii für 2 Personen, 13 Übernachtungen inkl. Flügen und Hoteltransfer verlost; der Einlöse Zeitraum beträgt 2
					Jahre.
				</p>
				<p className="mb-4">
					Der Gewinner stellt mit unserem Reisebüro des Vertrauens seine individuelle Traumreise im Wert von bis zu 10.000 € zusammen. Alle Details der Reise werden direkt zwischen
					Reisebüro und dem Gewinner abgestimmt.
				</p>
				<p className="mb-4">Des Weiteren werden 5 x Surfboards, 200 x Hawaiian Tropic Produktpakete im Wert von je 20 € und 500 x dm-Gutscheine im Wert von je 10 € verlost.</p>
				<p className="mb-4">
					Eine Barauszahlung der Gewinne ist nicht möglich. Gewinnansprüche können nicht auf andere Personen übertragen oder abgetreten werden. Der Rechtsweg ist ausgeschlossen.
				</p>
				<h3 className="h3 py-4">6. Datenschutz</h3>
				<p className="mb-4">
					Die Teilnehmerdaten (Vorname, Nachname, Adresse, E-Mail-Adresse und Geburtsdatum) werden vom Veranstalter und seinen Dienstleistern nur zum Zwecke der Durchführung und
					Abwicklung der Aktion gespeichert und verarbeitet (Art. 6 Abs. 1 lit. b DSGVO). Eine Weitergabe der Daten an Dritte erfolgt nicht. Der Veranstalter verarbeitet, je nach
					Gewinn, folgende personenbezogene Daten des Gewinners: Name und Kontaktdaten, ggf. Personalausweis und/ oder Reisepass, Bankverbindung [abhängig von der Durchführung der
					Reise]. Der Veranstalter verarbeitet personenbezogene Daten auf der Rechtsgrundlage zum Zweck der Durchführung des Gewinnspiels und der Übergabe der Preise an die
					Gewinner. Die Teilnehmer haben das Recht, Auskunft über die Verarbeitung ihrer personenbezogenen Daten zu erhalten; vom Veranstalter die Berichtigung, Löschung oder
					Einschränkung der Verarbeitung seiner personenbezogenen Daten zu verlangen und die bereitgestellten personenbezogenen Daten in einem strukturierten, gängigen und
					maschinenlesbaren Format zu erhalten und an einen anderen Verantwortlichen zu übermitteln. Zur Ausübung ihrer Rechte können sich die Teilnehmer per E-Mail an den
					Verantwortlichen wenden. Die Teilnehmer können jederzeit Widerspruch gegen die Verarbeitung einlegen. Soweit der Veranstalter die personenbezogenen Daten auf Grundlage
					einer Einwilligung verarbeitet, können die Teilnehmer diese jederzeit durch Kontaktaufnahme mit dem Veranstalter{" "}
					<a href="mailto:info@gewinnspiel-hawaiian-tropic.de" className="underline" target="_blank">
						info@gewinnspiel-hawaiian-tropic.de
					</a>{" "}
					widerrufen. Die Teilnehmer können auch eine Beschwerde bei der zuständigen Datenschutzbehörde einreichen. Nach vollständiger Abwicklung des Gewinnspiels, für Sachpreise
					spätestens bis zum 31.12.2024 und für die Reise bis zum erfolgten Antritt, spätestens 31.08.2026, werden alle im Rahmen des Gewinnspiels erhobenen personenbezogenen Daten
					gelöscht, sofern nicht gesetzliche Aufbewahrungsfristen einer Löschung entgegenstehen.
				</p>
				<h3 className="h3 py-4">7. Haftung</h3>
				<p className="mb-4">
					Der Veranstalter haftet nach den gesetzlichen Bestimmungen für eigenes Verschulden und grobe Fahrlässigkeit, sowie das seiner gesetzlichen Vertreter, leitenden
					Angestellten oder sonstigen Erfüllungsgehilfen. Das Gleiche gilt für die Übernahme von Garantien oder sonstiger verschuldensunabhängiger Haftung sowie für Ansprüche nach
					dem Produkthaftungsgesetz oder bei schuldhafter Verletzung von Leben, Körper oder Gesundheit. Der Veranstalter haftet dem Grunde nach für die eigene, die seiner
					Vertreter, leitenden Angestellten und einfachen Erfüllungsgehilfen einfache fahrlässige Verletzung wesentlicher Vertragspflichten, d.h. solcher Pflichten, auf deren
					Erfüllung der Teilnehmer regelmäßig vertrauen darf, um die Aktion ordnungsgemäß durchführen zu können; in diesem Fall ist die Haftung der Höhe nach auf den typischerweise
					entstehenden, vorhersehbaren Schaden begrenzt. Jede weitere Haftung des Veranstalters ist ausgeschlossen.
				</p>
				<h3 className="h3 py-4">8. Beendigung der Aktion</h3>
				<p className="mb-4">
					Die Aktion kann jederzeit ohne Vorankündigung und ohne Angabe von Gründen geändert, verlängert, für einen vorübergehenden Zeitraum ausgesetzt oder vorzeitig beendet
					werden. Hieraus entstehen keine Ansprüche gegen den Veranstalter.
				</p>
				<h3 className="h3 py-4">9. Sonstiges</h3>
				<p className="mb-4">
					Sollten einzelne Bestimmungen ganz oder teilweise unwirksam sein oder werden, bleibt die Gültigkeit der übrigen Teilnahmebedingungen hiervon unberührt. Ausschließlich
					anwendbares Recht ist das Recht der Bundesrepublik Deutschland. Sämtliche Fragen oder Anmerkungen zur Aktion sind per E-Mail an{" "}
					<a href="mailto:info@gewinnspiel-hawaiian-tropic.de" className="underline" target="_blank">
						info@gewinnspiel-hawaiian-tropic.de
					</a>{" "}
					zu richten.
				</p>
			</div>
			<div className="bg-beige py-10 pt-5 text-center text-xl text-red">
				<button onClick={() => navigate(-1)} className="btn btn-hero mt-8 rounded bg-red px-12 py-4 text-white">
					Zurück
				</button>
			</div>
		</>
	)
}
export default Terms
